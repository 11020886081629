
import { defineComponent, ref, computed } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { contactListsOptions } from '@/definitions/_general/_data/optionsList'
import type { ListChip } from '@/definitions/shared/types'

export default defineComponent({
  components: { TmButton, TmModal },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const action = ref<'move' | 'copy'>('move')
    const currentActionText = computed(() => action.value === 'move' ? 'Move' : 'Copy')
    const lists = ref<ListChip[]>([contactListsOptions[0]])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])

    return {
      action,
      currentActionText,
      lists,
      listsOptions,
    }
  },
})
